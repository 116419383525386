// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;900&display=swap');

/**
COLOR PALETTES
**/

$gray1: #fbfcfd;
$gray2: #f8f9fa;
$gray3: #f1f3f5;
$gray4: #eceef0;
$gray5: #e6e8eb;
$gray6: #dfe3e6;
$gray7: #d7dbdf;
$gray8: #c0c8ce;
$gray9: #889096;
$gray10: #7e868c;
$gray11: #687076;
$gray12: #11181c;

$grayDark1: #151718;
$grayDark2: #1a1d1e;
$grayDark3: #202425;
$grayDark4: #26292b;
$grayDark5: #2b2f31;
$grayDark6: #313538;
$grayDark7: #3a3f42;
$grayDark8: #4c5155;
$grayDark9: #697177;
$grayDark10: #787f85;
$grayDark11: #9ba1a6;
$grayDark12: #ecedee;

$blue1: #fafcff;
$blue2: #f5f9ff;
$blue3: #ebf3ff;
$blue4: #e0edff;
$blue5: #cee1fe;
$blue6: #b2d0fd;
$blue7: #92bcfc;
$blue8: #5c9bfa;
$blue9: #2173f0;
$blue10: #1068ef;
$blue11: #0f60dc;
$blue12: #002152;

$darkBlue1: #e9e8ec;
$darkBlue2: #d0ced6;
$darkBlue3: #b8b5c1;
$darkBlue4: #9f9bab;
$darkBlue5: #868196;
$darkBlue6: #6e6881;
$darkBlue7: #554e6b;
$darkBlue8: #2b2550;
$darkBlue9: #0b012b;
$darkBlue10: #0a0127;
$darkBlue11: #050015;
$darkBlue12: #090122;

$purple1: #fdfbff;
$purple2: #faf5fe;
$purple3: #ecddfd;
$purple4: #dbbffc;
$purple5: #ba85fa;
$purple6: #a35df8;
$purple7: #984af7;
$purple8: #8c36f7;
$purple9: #760ff5;
$purple10: #6c0ae6;
$purple11: #5a08bf;
$purple12: #2c045d;

$green1: #f8fcfa;
$green2: #f1f9f6;
$green3: #edf8f4;
$green4: #d9f2e8;
$green5: #c3eadb;
$green6: #ace2cd;
$green7: #86d5b8;
$green8: #2ac089;
$green9: #0f855a;
$green10: #0c6a47;
$green11: #095338;
$green12: #052e1f;

$orange1: #fffcfa;
$orange2: #fef9f6;
$orange3: #fff3eb;
$orange4: #ffe8d7;
$orange5: #ffdcc3;
$orange6: #ffcca7;
$orange7: #ffb585;
$orange8: #fa9856;
$orange9: #f76808;
$orange10: #ed5f00;
$orange11: #bd4b00;
$orange12: #4f2102;

$red1: #fefafb;
$red2: #fef6f6;
$red3: #fde7e9;
$red4: #fed8da;
$red5: #fdc9cc;
$red6: #fcbabf;
$red7: #f99aa0;
$red8: #f76e77;
$red9: #e5425e;
$red10: #ee1624;
$red11: #df111e;
$red12: #72080f;

$blackAlpha1: rgba(0, 0, 0, 0.01);
$blackAlpha2: rgba(0, 0, 0, 0.03);
$blackAlpha3: rgba(0, 0, 0, 0.05);
$blackAlpha4: rgba(0, 0, 0, 0.06);
$blackAlpha5: rgba(0, 0, 0, 0.08);
$blackAlpha6: rgba(0, 0, 0, 0.12);
$blackAlpha7: rgba(0, 0, 0, 0.16);
$blackAlpha8: rgba(0, 0, 0, 0.24);
$blackAlpha9: rgba(0, 0, 0, 0.4);
$blackAlpha10: rgba(0, 0, 0, 0.48);
$blackAlpha11: rgba(0, 0, 0, 0.56);
$blackAlpha12: rgba(0, 0, 0, 0.88);

$whiteAlpha1: rgba(255, 255, 255, 0.01);
$whiteAlpha2: rgba(255, 255, 255, 0.03);
$whiteAlpha3: rgba(255, 255, 255, 0.05);
$whiteAlpha4: rgba(255, 255, 255, 0.06);
$whiteAlpha5: rgba(255, 255, 255, 0.08);
$whiteAlpha6: rgba(255, 255, 255, 0.12);
$whiteAlpha7: rgba(255, 255, 255, 0.16);
$whiteAlpha8: rgba(255, 255, 255, 0.24);
$whiteAlpha9: rgba(255, 255, 255, 0.4);
$whiteAlpha10: rgba(255, 255, 255, 0.48);
$whiteAlpha11: rgba(255, 255, 255, 0.56);
$whiteAlpha12: rgba(255, 255, 255, 0.88);
$white: rgba(255, 255, 255, 1);

/*
GLOBAL VARIABLES for Colors
*/
$contentPrimary: $gray12;
$contentSecondary: $gray9;
$contentDisabled: $gray8;
$contentLink: $blue11;
$contentLifo: $blue11;
$contentNegative: $red11;
$contentPositive: $green11;
$contentWarning: $orange11;
$contentContrastPrimary: $whiteAlpha12;
$contentContrastSecondary: $whiteAlpha11;
$contentContrastPlaceholder: $whiteAlpha8;
$contentContrastDisabled: $whiteAlpha8;

$bgSurface: $whiteAlpha12;
$bgSurface_1: $gray1;
$bgSurface_2: $gray2;
$bgSurface_dark: $grayDark1;
$bgNeutral: $gray9;
$bgInfo: $blue9;
$bgNegative: $red9;
$bgPositive: $green9;
$bgWarning: $orange9;
$bgNeutralSubtle: $gray3;
$bgInfoSubtle: $blue3;
$bgNegativeSubtle: $red3;
$bgPositiveSubtle: $green3;
$bgWarningSubtle: $orange3;

$borderDividers: $gray6;
$borderNegative: $red7;
$borderPositive: $green7;
$borderWarning: $orange7;
$borderNeutral: $gray7;
$borderInfo: $blue7;

$actionNormal: $blue9;
$actionNegative: $red9;
$actionPositive: $green9;
$actionNeutral: $gray9;
$actionNormalHover: $blue10;
$actionNegativeHover: $red10;
$actionPositiveHover: $green10;
$actionNeutralHover: $gray10;
$actionPrimaryActive: $blue11;
$actionNegativeActive: $red11;
$actionPositiveActive: $green11;
$actionNeutralActive: $gray11;

$defaultHover: $gray3;
$defaultActive: $gray4;
$defaultSelected: $blue3;
$defaultSelectedHover: $blue4;
$defaultSelectedActive: $blue5;

$defaultBorderNormal: $gray7;
$defaultBorderHover: $gray8;
$defaultBorderActive: $gray9;
$defaultBorderFocus: $blue9;
$defaultBorderAlert: $red8;
$defaultBorderSuccess: $green8;

$defaultNormal: $gray9;
$defaultSubtle: $gray2;

$defaultContrastHover: $blackAlpha3;
$defaultContrastActive: $blackAlpha4;

$navyBlue: $purple9;
$gray: $gray12;
$naturalSecondary: #f7f7f8;
$linkColor: #760ff5;

$fontFamilyInter: 'Inter', sans-serif;
$fontFamilyOverpass: 'Overpass', sans-serif;
$fontFamilyPoppins: 'Poppins', sans-serif;
$fontFamilyComicNeue: 'Comic Neue', sans-serif;
$fontFamilyIowanOldStyle: 'IowanOldSt BT', serif;
$fontSizeMedium40: 40px;
$fontSizeMedium32: 32px;
$fontSizeMedium24: 24px;
$fontSizeMedium18: 18px;
$fontSizeMedium16: 16px;
$fontSizeMedium14: 14px;
$fontSizeMedium12: 12px;

$fontSizeSemiBold40: 40px;
$fontSizeSemiBold32: 32px;
$fontSizeSemiBold24: 24px;
$fontSizeSemiBold18: 18px;
$fontSizeSemiBold16: 16px;
$fontSizeSemiBold14: 14px;
$fontSizeSemiBold12: 12px;

$fontSizeCodeNormal: 14px;
$fontSizeCodeSmall: 12px;

$actionPrimaryNormal: $purple9;
$actionPrimaryHover: $purple10;
$actionPrimaryActive: $purple11;

$borderRadius: 6px;

$maxWidth: 1440px;
$websiteBg: $darkBlue9;

$brPoint1: 1330px;
$brPoint2: 1120px;
$brPoint3: 992px;
$brPoint4: 768px;
$brPoint5: 576px;
@import '../../node_modules/antd/dist/antd.min.css';
@import '../../node_modules/react-image-crop/src/ReactCrop.scss';
@import '../common/fonts/iown-old-style/stylesheet.css';

@import './styles/variables';


html,
body {
  font-family: $fontFamilyInter;
  font-weight: 500;
  color: $contentSecondary;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #fff;
}

.poppins {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.cardo {
  font-family: 'Cardo', serif;
  font-weight: 700;
}

.inter {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

a,
.link,
.ant-typography a,
a.ant-typography {
  color: $linkColor;
  text-decoration: none;

  &:hover,
  .active,
  &:visited {
    color: darken($linkColor, 10%);
  }

}

// span.ant-typography {
//   color: $contentSecondary;
// }

.ant-btn {
  border-radius: $borderRadius;
  padding: 10px 16px;
  height: 40px;
  line-height: 1;
  border-color: #d0d5dd;
  box-shadow: 0px 1px 2px 0px #11181c14;
  font-size: 14px;
  font-weight: 600;

  &:hover,
  &:focus {
    color: $gray12;
    border-color: $gray4;
  }
}

.ant-btn-primary {
  background-color: $actionPrimaryNormal;
  border-color: $actionPrimaryNormal;
  color: #fff;

  &:hover,
  &.active {
    color: #fff;
    background-color: $actionPrimaryHover;
    border-color: $actionPrimaryHover;
  }

  &:focus {
    color: #fff;
    background-color: $actionPrimaryHover;
    border-color: $actionPrimaryHover;
    outline: 3px solid #92bcfc;
  }

  &:active {
    background-color: $actionPrimaryActive;
    border-color: $actionPrimaryActive;
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  border: 1px solid #D7DBDF;
  border-radius: $borderRadius;
  box-shadow: 0px 1px 0px 0px #11181c14;

  input.ant-input {
    padding: 10px 12px;
  }

  input.ant-input[type='password'] {
    border-radius: $borderRadius 0 0 $borderRadius;
  }

  .ant-input-suffix {
    min-width: 30px;
    align-items: center;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover,
  &:hover {
    border: 1px solid #D7DBDF;
  }

  &:focus-within {
    outline: 2px solid #5C9BFA;
  }

}

.ant-input {
  padding: 10px 12px;
  border: 1px solid #D7DBDF;
  border-radius: $borderRadius;
  box-shadow: 0px 1px 0px 0px #11181c14;

  &:hover {
    border: 1px solid #D7DBDF;
  }

  &:focus {
    outline: 2px solid #5C9BFA;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $actionPrimaryNormal;
  border-color: $actionPrimaryNormal;
}

.ant-form-item-label {
  font-size: 14px;
  font-weight: 500;
  color: #1B262C;
}

.ant-form-item-explain {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #60676C;
  padding-top: 8px;
}

.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  border-color: $red9;
  background: #fff;

  &:focus {
    outline: 2px solid $red9;
  }
}

.ant-form-item-explain-error {
  color: $red9;
}

.ant-avatar {
  background: transparent;
  color: $actionPrimaryNormal;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $purple2;
  }
}

.ant-scroll-number {
  font-weight: 600;
}

::placeholder {
  color: $contentSecondary  !important;
  opacity: 1;
}

/*
  * Custom Styles
  */

/* Overriding modals*/
.ant-modal-wrap {
  background: rgba($color: $naturalSecondary, $alpha: 0.5);

  .ant-modal-content {
    border-radius: $borderRadius;
    box-shadow: 0px 8px 40px rgba(17, 24, 28, 0.2),
      0px 1px 3px rgba(17, 24, 28, 0.16);
    overflow: hidden;
  }

  .ant-modal-header {
    border-radius: $borderRadius $borderRadius 0 0;
    box-shadow: inset 0px 1px 0px 0px #dfe3e6, inset 0px 1px 0px 0px #dfe3e6;

    .ant-modal-title {
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #1B262C;
    }
  }

  .and-modal-footer {
    border-radius: 0 0 $borderRadius $borderRadius;
    box-shadow: inset 0px -1px 0px 0px #dfe3e6, inset 0px -1px 0px 0px #dfe3e6;
  }

  .ant-modal-close-x {
    font-size: 16px;
    font-weight: 500;
    color: #1B2637;
  }

  .ant-modal-body {
    padding: 20px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    // add inner shadow
  }
}

.ant-tag {
  border-radius: $borderRadius;
}

.ql-bubble .ql-tooltip {
  border-radius: $borderRadius;
  background: $bgSurface;
  border-color: $borderNeutral;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom-color: $bgSurface;
}

.ql-bubble .ql-picker {
  color: $contentPrimary  !important;
}

.ql-bubble .ql-stroke {
  stroke: $contentPrimary;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  border-color: $borderNeutral;
  background: $bgSurface;
  border-radius: $borderRadius;
  // add elevation
  box-shadow: 0px 8px 40px rgba(17, 24, 28, 0.2),
    0px 1px 3px rgba(17, 24, 28, 0.16);
}

.ql-bubble.ql-toolbar button:hover,
.ql-bubble .ql-toolbar button:hover,
.ql-bubble.ql-toolbar button:focus,
.ql-bubble .ql-toolbar button:focus,
.ql-bubble.ql-toolbar button.ql-active,
.ql-bubble .ql-toolbar button.ql-active,
.ql-bubble.ql-toolbar .ql-picker-label:hover,
.ql-bubble .ql-toolbar .ql-picker-label:hover,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active,
.ql-bubble.ql-toolbar .ql-picker-item:hover,
.ql-bubble .ql-toolbar .ql-picker-item:hover,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: $linkColor  !important;
}

.ql-bubble.ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble.ql-toolbar button:focus .ql-stroke,
.ql-bubble .ql-toolbar button:focus .ql-stroke,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble.ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble .ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $linkColor;
}

.ql-bubble .ql-tooltip-editor input[type='text'] {
  color: $contentPrimary  !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a::before {
  background: $purple1;
  color: $contentPrimary  !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a::after {
  border-top-color: $purple1;
}

.ant-menu-submenu-arrow {
  display: none;
}

.note * {
  color: inherit !important;
  font-family: $fontFamilyComicNeue;
  font-weight: 700;
}

// for tags

.ant-tag {
  border-radius: $borderRadius;
  border-color: $actionPrimaryNormal;
  color: $actionPrimaryNormal;

  >.anticon-close {
    color: $actionPrimaryNormal;
  }
}

.tag-input {
  .ant-input {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0 !important;
    height: 20px !important;
    line-height: 20px !important;
    font-size: 14px !important;

    &::placeholder {
      color: $actionPrimaryNormal  !important;
      opacity: 1 !important;
      font-size: 12px !important;
    }

    &:focus {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .ant-input-wrapper {
    font-size: 12px !important;
    line-height: 1 !important;
  }

  .ant-input-group-addon {
    padding: 0 10px !important;
    font-size: 12px !important;
    line-height: 1 !important;
    height: 20px !important;
    background: transparent !important;
    border: none !important;

    .anticon {
      color: $actionPrimaryNormal;

      &.disabled {
        visibility: hidden !important;
      }
    }
  }
}

.ant-select-focused .tag-input,
.has-search-value .tag-input {
  border-bottom: $actionPrimaryNormal solid 1px;

}

/*
Global style goes here
*/
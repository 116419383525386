.help-text {
  margin-bottom: 15px;
  display: block;
  text-align: right;
  margin-top: 5px;
}

.form-area {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

@import '../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: -25px;
  margin-right: -25px;
  background: $bgSurface;
}

.top-area {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  border-bottom: $borderNeutral solid 1px;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.bottom-area {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 64px);
}

.side-area {
  display: flex;
  flex-direction: column;
  width: 102px;
  height: 100%;
  position: relative;
}

.menu-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
}

.editor-area {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  border-left: $borderNeutral solid 1px;
}

.comment-area {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  border-left: $borderNeutral solid 1px;
  position: relative;
}

.comment-wrapper {
  position: absolute;
  height: 100%;
  overflow: auto;
  left: 0;
  top: 0;
}

.editor-area-bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 64px;
  border-top: $borderNeutral solid 1px;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.tag-list {
  min-width: 250px;
  max-width: 50%;

  // class ends with -control
  :global([class*='-control']) {
    border-color: transparent;
    box-shadow: none;
  }
  :global([class*='-multiValue']) {
    border-radius: $borderRadius;
    color: $contentPrimary;
    font-size: $fontSizeMedium14;
    background: $bgSurface;
    border: $borderNeutral solid 1px;
  }
  :global([class*='-IndicatorsContainer']) {
    opacity: 0;
  }
  &:hover :global([class*='-control']) {
    border-color: $borderNeutral;
  }
  &:hover :global([class*='-IndicatorsContainer']) {
    opacity: 1;
  }
}

.action-btn {
  border: none;
  color: $contentSecondary;
  box-shadow: none;
  padding: 0;
}

.editor-area-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 64px);
  position: relative;
}

.back-btn {
  border: none;
  box-shadow: none;
}

.editor-menu {
  border-right: none;
  :global(.ant-menu-item) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78px !important;
    margin: 12px !important;
    width: 72px !important;
    padding: 0 !important;
    color: $contentSecondary !important;
  }
  :global(.ant-menu-title-content) {
    overflow: visible !important;
    line-height: 1.5;
    font-size: $fontSizeMedium12;
    flex: 0 !important;
    margin-left: 0 !important;
  }
  :global(.ant-menu-item-icon) {
    font-size: 2rem;
  }

  :global(.ant-menu-submenu-title) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78px !important;
    margin: 12px !important;
    width: 72px !important;
    padding: 0 !important;
    color: $contentSecondary !important;
  }

  :global(.ant-menu-submenu-arrow) {
    top: 90% !important;
    left: 50% !important;
    transform: translate(-50%, -100%) !important;
  }

  :global(.ant-menu-submenu-title:hover),
  :global(.ant-menu-submenu-title:focus),
  :global(.ant-menu-submenu-title.ant-menu-submenu-selected),
  :global(.ant-menu-item:hover),
  :global(.ant-menu-item:focus),
  :global(.ant-menu-item.ant-menu-item-selected) {
    border-radius: $borderRadius;
    background: #eceef0;
    border: none;
    color: $contentPrimary !important;
    &::after {
      display: none;
    }
  }
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.menu {
  border-right: none;
  :global(.ant-menu-item) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78px !important;
    margin: 0 !important;
    padding: 8px 16px !important;
    width: 72px !important;
  }
  :global(.ant-menu-title-content) {
    margin-left: 0 !important;
  }
  :global(.ant-menu-item-icon) {
    font-size: 2rem;
  }

  :global(.ant-menu-submenu-title) {
    display: flex;
    flex-direction: column;
    height: 88px !important;
    align-items: center;
    justify-content: center;
    padding: 8px 16px 16px !important;
    width: 72px !important;
  }

  :global(.ant-menu-submenu-arrow) {
    top: 90% !important;
    left: 50% !important;
    transform: translate(-50%, -100%) !important;
  }
}

@import 'variables';

.font-iowan-old-style {
    font-family: $fontFamilyIowanOldStyle;
    font-style: normal;
    font-weight: 900;
}

.font-inter {
    font-family: $fontFamilyInter;
    font-style: normal;
    font-weight: 600;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@font-face {
    font-family: 'IowanOldSt BT';
    src: url('IowanOldStyleBT-Roman.eot');
    src: url('IowanOldStyleBT-Roman.eot?#iefix') format('embedded-opentype'),
        url('IowanOldStyleBT-Roman.woff2') format('woff2'),
        url('IowanOldStyleBT-Roman.woff') format('woff'),
        url('IowanOldStyleBT-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

